<template>
  <div>
    <h1 class="white--text">La carte proposée par {{ this.$route.params.bar | capitalize }}</h1>

    <img
      v-if="img_exist"
      class="img_menu pl-5"
      :src="'data:image;base64,' + img"
    />
  </div>
</template>

<script>
const axios = require("axios");

export default {
  data() {
    return {
      img: null,
      img_exist: false,
    };
  },
  methods: {
    check_params() {
      axios
        .post(this.$store.state.url + "/check_bar", {
          bar_id: this.$route.params.bar,
        })
        .then((response) => {
          if (response.data.code != 200) {
            this.$store.state.authorize = false;
            this.$router.push("/NotFound");
          } else {
            this.$store.state.authorize = true;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    get_img_page_suggestion() {
      axios
        .get(this.$store.state.url + "/get_img_page_suggestion", {
          params: {
            bar_id: this.$route.params.bar,
          },
        })
        .then((response) => {
          if (response.data.code != 400) {
            this.img = response.data;
            this.img_exist = true;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  filters: {
    capitalize: function (value) {
      if (!value) return "";
      value = value.toString();
      return value.charAt(0).toUpperCase() + value.slice(1);
    },
  },
  created() {
    this.check_params();
    this.get_img_page_suggestion();
  },
};
</script>

<style>
.img_menu {
  width: 20em;
}
</style>